<template>
  <div class="card">
    <div
      v-if="block.type"
      class="card-header"
    >
      <h3
        v-b-toggle="`collapseFormBlock-${index}`"
        class="cart-title"
      >
        <a data-action="collapse">{{ block.title ? block.title : ('This is a ' + block.type.toLowerCase().replace('_', ' ')) }} <small class="badge bg-light-secondary type__form">{{ block.type.replace('_', ' ').toUpperCase() }}</small></a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a
              v-b-toggle="`collapseFormBlock-${index}`"
            >
              <i data-feather="chevron-down" />
            </a>
          </li>
          <li>
            <a
              data-action="reload"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Reload"
              @click="resetBlock"
            >
              <i data-feather="rotate-cw" />
            </a>
          </li>
          <li>
            <a
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Duplicate"
              @click="duplicate(index)"
            >
              <i data-feather="copy" />
            </a>
          </li>
          <li>
            <span
              class="disp-in"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Delete"
              @click="deleteIndex(index)"
            >
              <a
                data-action="close"
                data-bs-toggle="modal"
                data-bs-target="#modalDeleteWithoutInput"
              ><i data-feather="x" /></a>
            </span>
          </li>
          <li>
            <a
              href=""
              class="btn bg-light-secondary"
            >
              <i data-feather="move" />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content">
      <b-collapse
        :id="`collapseFormBlock-${index}`"
        :visible="isVisible || !block.id"
      >
        <div
          v-if="!loading"
          class="card-body pt-0"
        >
          <FormBlockContent :block="originalData" />
        </div>
        <div
          v-if="loading"
          class="loading custom-loading mb-2 ms-2"
        >
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import FormBlockContent from './FormBlockContent.vue'

export default {
  components: {
    FormBlockContent,
    BCollapse,
  },
  props: {
    block: { type: Object, required: true },
    index: { type: Number, required: true },
    isVisible: { type: Boolean, required: true },
  },
  data() {
    return {
      originalData: {},
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
    }),
  },
  watch: {
    originalData: {
      handler() {
        this.$emit('saveBlock', {
          data: this.originalData,
          index: this.index,
        })
      },
      deep: true,
    },
  },
  async mounted() {
    this.resetBlock()
  },
  methods: {
    deleteIndex(index) {
      this.$emit('deleteIndex', index)
    },
    duplicate(index) {
      this.$emit('duplicateIndex', index)
    },
    resetBlock() {
      this.loading = true
      this.originalData = { ...this.block }
      setTimeout(() => {
        this.loading = false
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
  },
}
</script>
