<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ form.id ? 'Edit' : 'New' }} request form<br>
                <div class="mt-1">
                  <div class="form-check form-check-success form-switch d-flex align-items-center">
                    <input
                      id="publishContent"
                      v-model="form.active"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="publishContent"
                    >
                      <small class="ms-1"> Active</small>
                    </label>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to areas & groups list"
              @click="$router.push({name: 'admin.request-forms.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <form
            id="edit-form"
            action=""
          >
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="cart-title">
                    <a data-action="collapse">Basic information</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content collapse show">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Name</label>
                          <input
                            v-model="form.title"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Style</label>
                          <v-select
                            v-model="form.style"
                            label="name"
                            :options="[
                              {name: 'Opened', value: 0},
                              {name: 'By invitation', value: 1},
                            ]"
                            :get-option-key="option => option.value"
                            :reduce="e => e.value"
                          />
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Reminder</label>
                          <v-select
                            v-model="form.reminder"
                            label="name"
                            :options="[
                              {name: 'No', value: false},
                              {name: 'Yes', value: true},
                            ]"
                            :get-option-key="option => option.value"
                            :reduce="e => e.value"
                          />
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Start date</label>
                          <date-picker
                            v-model="form.start_date"
                            format="D MMM Y"
                            value-type="format"
                            class="w-100"
                          />
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >End date</label>
                          <date-picker
                            v-model="form.end_date"
                            format="D MMM Y"
                            value-type="format"
                            class="w-100"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Notify</label>
                          <v-select
                            v-model="form.notify_to"
                            label="name"
                            multiple
                            :options="[
                              {name: 'Isabel', value: 31},
                              {name: 'Sonia', value: 32},
                              {name: 'Angel', value: 3669},
                            ]"
                            :get-option-key="option => option.value"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >URL</label>
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              :placeholder="slug"
                              aria-describedby="button-addon2"
                              readonly="readonly"
                            >
                            <button
                              id="button-addon2"
                              class="btn btn-outline-primary"
                              type="button"
                              @click="copySlug"
                            >
                              Copy
                            </button>
                            <!-- <button class="btn btn-success" id="button-addon2" type="button">Copied!</button>  -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Description</label>
                          <quill-editor v-model="form.description" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Audience
                :data="{ ...form.audience }"
                :is-form="true"
                :show-cae="false"
                @saveAudience="saveAudience"
              />

              <h3 class="mt-4 mb-2">Configure form
                <small class="float-end">
                  <a
                    class="text-dark"
                    @click="toggle(false)"
                  ><i data-feather="minimize-2" /> collapse all</a>
                  <a
                    class="ms-2 text-dark"
                    @click="toggle(true)"
                  ><i data-feather="maximize-2" /> uncollapse all</a>
                </small>
              </h3>

              <draggable
                v-model="form.new_blocks"
                group="favor"
                @start="drag=true"
                @end="drag=false"
              >
                <li
                  v-for="block, index in form.new_blocks"
                  :key="block.order"
                  class="draggable"
                  style="list-style-type: none;"
                >
                  <FormBlock
                    :is-visible="visible"
                    :index="index"
                    :block="block"
                    @deleteIndex="deleteIndex"
                    @duplicateIndex="duplicateIndex"
                    @saveBlock="saveBlock"
                  />
                </li>
              </draggable>
            </div>
          </form>
        </div>
        <hr>
        <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <button
              type="button"
              class="btn btn-dark"
              style="border-bottom-right-radius: 0;border-top-right-radius: 0;"
            >
              Add a form element
            </button>
            <button
              type="button"
              class="btn btn-dark dropdown-toggle dropdown-toggle-split"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style="border-bottom-left-radius: 0;border-top-left-radius: 0;"
            >
              <span class="visually-hidden">Toggle Dropdown</span>
            </button>
          </template>
          <div
            class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
          >
            <b-dropdown-item @click="addFormElement('title_section')">
              Title section
            </b-dropdown-item>
            <b-dropdown-item @click="addFormElement('text_paragraph')">
              Text paragraph
            </b-dropdown-item>
            <b-dropdown-item @click="addFormElement('multimedia')">
              Multimedia
            </b-dropdown-item>
            <div class="dropdown-divider" />
            <b-dropdown-item @click="addFormElement('basic_input')">
              Basic input
            </b-dropdown-item>
            <b-dropdown-item @click="addFormElement('long_answer')">
              Long answer
            </b-dropdown-item>
            <b-dropdown-item @click="addFormElement('several_options')">
              Several options
            </b-dropdown-item>
            <b-dropdown-item @click="addFormElement('date_hour')">
              Date and Hour
            </b-dropdown-item>
            <b-dropdown-item @click="addFormElement('download')">
              Download file
            </b-dropdown-item>
            <b-dropdown-item @click="addFormElement('upload')">
              Upload file
            </b-dropdown-item>
            <b-dropdown-item @click="addFormElement('linear_scale')">
              Linear scale
            </b-dropdown-item>
            <div class="dropdown-divider" />
            <b-dropdown-item @click="addFormElement('divider')">
              Divider
            </b-dropdown-item>
          </div>
        </b-dropdown>
        <hr>
        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import * as clipboard from 'clipboard-polyfill'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import Draggable from 'vuedraggable'
import Audience from '../labour-hazards/components/Audience.vue'
import FormBlock from './components/FormBlock.vue'

export default {
  components: {
    DatePicker,
    Audience,
    FormBlock,
    BDropdown,
    BDropdownItem,
    Draggable,
  },
  data() {
    return {
      formId: this.$route.params.id,
      sending: false,
      errors: '',
      visible: false,
    }
  },
  computed: {
    ...mapGetters({
      form: 'requestForms/item',
    }),
    currentOrder() {
      let maxOrder = 0

      this.form.new_blocks.forEach(e => {
        if (e.order > maxOrder) {
          maxOrder = e.order
        }
      })

      return maxOrder
    },
    slug() {
      const str = this.slugify()
      return `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/intranet/forms/${str}`
    },
  },
  async mounted() {
    if (this.formId) {
      await this.$store.dispatch('requestForms/fetchId', this.formId)
      this.form.new_blocks = this.form.blocks
    } else {
      await this.$store.dispatch('requestForms/cleanType')
    }
  },
  methods: {
    addFormElement(type) {
      this.form.new_blocks.push({
        order: this.currentOrder + 1,
        type,
        answer_type: 0,
        design: 0,
        options: [],
      })
    },
    copySlug() {
      clipboard.writeText(this.slug).then(
        () => {
          this.$toastr.success('', 'Copied to clipboard')
        },
        () => {
          this.$toastr.error('', 'Failed to clipboard')
        },
      )
    },
    saveAudience(data) {
      this.form.audience = data
    },
    toggle(visibility) {
      this.visible = !visibility
      setTimeout(() => {
        this.visible = visibility
      }, 50)
    },
    slugify() {
      let str = this.form.title ?? ''
      str = str.replace(/^\s+|\s+$/g, '') // trim
      str = str.toLowerCase()

      // remove accents, swap ñ for n, etc
      const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
      const to = 'aaaaeeeeiiiioooouuuunc------'
      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }

      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes

      return str
    },
    async save() {
      this.sending = true
      this.form.slug = this.slugify()
      try {
        if (this.formId) {
          await this.$store.dispatch('requestForms/update', { id: this.formId, data: this.form })
        } else {
          await this.$store.dispatch('requestForms/create', this.form)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.form.id) {
        Vue.swal('The form has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'admin.request-forms.index' })
        })
      }

      this.sending = false
    },
    deleteIndex(index) {
      this.form.new_blocks.splice(index, 1)
    },
    duplicateIndex(index) {
      this.form.new_blocks.push({
        ...this.form.blocks[index],
        order: this.currentOrder + 1,
      })
    },
    saveBlock(data) {
      Vue.set(this.form.new_blocks, data.index, { ...data.data })
    },
  },
}
</script>
