<template>
  <div class="block-form">
    <SectionTitle
      v-if="block.type.toLowerCase() === 'title_section'"
      :block="block"
    />

    <TextParagraph
      v-if="block.type.toLowerCase() === 'text_paragraph'"
      :block="block"
    />

    <BasicInput
      v-if="block.type.toLowerCase() === 'basic_input'"
      :block="block"
    />

    <LongAnswer
      v-if="block.type.toLowerCase() === 'long_answer'"
      :block="block"
    />

    <SeveralOptions
      v-if="block.type.toLowerCase() === 'several_options'"
      :block="block"
    />

    <DateHour
      v-if="block.type.toLowerCase() === 'date_hour'"
      :block="block"
    />

    <Download
      v-if="block.type.toLowerCase() === 'download'"
      :block="block"
    />

    <Upload
      v-if="block.type.toLowerCase() === 'upload'"
      :block="block"
    />

    <LinearScale
      v-if="block.type.toLowerCase() === 'linear_scale'"
      :block="block"
    />

    <Multimedia
      v-if="block.type.toLowerCase() === 'multimedia'"
      :block="block"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionTitle from './types/SectionTitle.vue'
import BasicInput from './types/BasicInput.vue'
import DateHour from './types/DateHour.vue'
import Download from './types/Download.vue'
import LinearScale from './types/LinearScale.vue'
import LongAnswer from './types/LongAnswer.vue'
import Multimedia from './types/Multimedia.vue'
import SeveralOptions from './types/SeveralOptions.vue'
import TextParagraph from './types/TextParagraph.vue'
import Upload from './types/Upload.vue'

export default {
  components: {
    SectionTitle,
    BasicInput,
    DateHour,
    Download,
    LinearScale,
    LongAnswer,
    Multimedia,
    SeveralOptions,
    TextParagraph,
    Upload,
  },
  props: {
    block: { type: Object, required: true },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
    }),
  },
  mounted() {},
}
</script>
