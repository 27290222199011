<template>
  <div class="card">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">{{ isForm ? 'To invite:' : 'Audience:' }}
          <span
            v-if="isForm"
            class="badge bg-light-success"
          >{{ data.users ? data.users.length : 0 }}</span>
        </a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a
              v-b-toggle="`collapseAudience`"
              data-action="collapse"
            ><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseAudience`"
      visible
    >
      <div class="card-body">
        <b-tabs>
          <b-tab
            :active="!$route.query.section || !$route.query.host"
          >
            <template #title>
              All researchers
            </template>
            <div
              id="home"
              class="tab-pane active"
              aria-labelledby="home-tab"
              role="tabpanel"
            >
              <div class="form-check form-check-success mb-2">
                <input
                  id="colorCheck3"
                  v-model="allResearchers"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for="colorCheck3"
                >All researchers</label>
              </div>
              <!-- <p><em>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat dicta enim commodi nam a blanditiis illum iusto aperiam autem, dolor porro, debitis soluta doloremque quos consectetur expedita quam necessitatibus quia?</em></p> -->
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              Single selection
            </template>
            <div
              id="profile"
              class="tab-pane"
              aria-labelledby="profile-tab"
              role="tabpanel"
            >
              <div class="table-responsive-sm">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="check__column">
                        <label
                          for=""
                          class="block-label"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="select or deselect all"
                        ><input type="checkbox"></label>
                      </th>
                      <th>Name</th>
                      <th>Host</th>
                      <th class="text-end">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="researcher, index in selectedUsers"
                      :key="`r-id-${index}-${researcher.id}`"
                    >
                      <td>
                        <label
                          for=""
                          class="block-label"
                        >
                          <input
                            type="checkbox"
                            checked="checked"
                          >
                        </label>
                      </td>
                      <td>{{ researcher.name }}</td>
                      <td>{{ researcher.current_host ? researcher.current_host.code : (researcher.hosts ? researcher.hosts.map(el => el.name).join(', ') : '') }}</td>
                      <td class="text-end">
                        <a
                          :href="$router.resolve({name: 'researcher.dashboard', params: { id: researcher.id }}).href"
                          target="_blank"
                          class="btn btn-icon btn-icon rounded-circle btn-flat-dark me-1"
                        ><i data-feather="eye" /></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <a
                v-b-toggle="`collapseAudienceUser`"
                class="btn btn-sm btn-dark dis-ib mt-3"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="collapseAddResearcher"
              ><i data-feather="plus" /> Add researcher</a>

              <b-collapse
                :id="`collapseAudienceUser`"
              >
                <div class="mt-1 card shadow-none bg-transparent border-secondary">
                  <div class="card-body">
                    <h3>Add Researcher</h3>
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Researcher</label>
                          <v-select
                            v-model="user"
                            label="name"
                            :options="users"
                            :get-option-key="option => option.id"
                            @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-2">
                      <a
                        v-b-toggle="`collapseAudienceUser`"
                        class="btn btn-sm btn-outline-success me-1"
                        @click="addUser"
                      >Add</a>
                      <a
                        v-b-toggle="`collapseAudienceUser`"
                        class="btn btn-sm btn-flat-danger"
                      >Cancel</a>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              Selection by groups
            </template>
            <div
              id="about"
              class="tab-pane"
              aria-labelledby="about-tab"
              role="tabpanel"
            >
              <div class="row d-flex align-items-end mb-3">
                <div class="col-sm-4">
                  <label
                    for=""
                    class="form-label"
                  >Host</label>
                  <v-select
                    v-model="host"
                    label="name"
                    :options="hosts"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'institutions/filter')"
                  />
                </div>
                <div
                  v-if="host"
                  class="col-sm-3"
                >
                  <label
                    for=""
                    class="form-label"
                  >Department</label>
                  <v-select
                    v-model="department"
                    label="name"
                    :options="host.departments"
                    :get-option-key="option => option.id"
                  />
                </div>
                <div class="col-sm-2">
                  <label
                    for=""
                    class="form-label"
                  >Workplace</label>
                  <v-select
                    v-model="workplace"
                    label="name"
                    :options="workplaces"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'workplaces/filter')"
                  />
                </div>
                <div class="col-sm-2">
                  <label
                    for=""
                    class="form-label"
                  >Hazard</label>
                  <v-select
                    v-model="hazard"
                    label="name"
                    :options="hazards"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'hazards/filter')"
                  />
                </div>
                <div class="col-sm-1">
                  <a
                    class="btn btn-dark"
                    @click="searchGroup"
                  >
                    <i data-feather="search" />
                  </a>
                </div>
              </div>
              <Search
                v-for="search, index in searches"
                :key="index"
                :index="index"
                :search="search"
                :users="selectedUsers"
                :is-form="isForm"
                @toggleUser="toggleUser"
                @removeSearch="removeSearch"
              />
            </div>
          </b-tab>
          <b-tab
            v-if="showCae"
            :active="$route.query.section && $route.query.host"
          >
            <template #title>
              CAE
            </template>
            <div
              id="home"
              class="tab-pane active"
              aria-labelledby="home-tab"
              role="tabpanel"
            >
              <div class="form-check form-check-success mb-2">
                <input
                  id="colorCheck3"
                  v-model="allCaes"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for="colorCheck3"
                >All CAEs</label>
              </div>
              <!-- <p><em>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat dicta enim commodi nam a blanditiis illum iusto aperiam autem, dolor porro, debitis soluta doloremque quos consectetur expedita quam necessitatibus quia?</em></p> -->
            </div>
            <div
              v-if="!allCaes"
              class="row"
            >
              <div class="col-sm-6">
                <div class="mb-1">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Type to filter by name"
                    @input="searchCae($event.target.value)"
                  >
                </div>
              </div>
            </div>
            <div
              v-if="!allCaes"
              class="row"
            >
              <div class="col">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="check__column">
                        <label
                          for=""
                          class="block-label"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="select or deselect all"
                          @click="selectAllCae($event.target.checked)"
                        ><input type="checkbox"></label>
                      </th>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="hostCae in selectedCaes"
                      :key="`j-${hostCae.id}`"
                    >
                      <td>
                        <label
                          for=""
                          class="block-label"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="select or deselect all"
                          @click="selectCae($event, hostCae)"
                        >
                          <input
                            type="checkbox"
                            checked
                          >
                        </label>
                      </td>
                      <td>{{ hostCae.name + ' - ' + hostCae.institution.code }}</td>
                    </tr>
                    <tr
                      v-for="hostCae in caeHosts"
                      :key="`l-${hostCae.id}`"
                    >
                      <td>
                        <label
                          for=""
                          class="block-label"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="select or deselect all"
                          @click="selectCae($event, hostCae)"
                        >
                          <input type="checkbox">
                        </label>
                      </td>
                      <td>{{ hostCae.name + ' - ' + hostCae.institution.code }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import { BCollapse, BTab, BTabs } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Vue from 'vue'
import Search from './Search.vue'

export default {
  components: {
    BCollapse,
    BTab,
    BTabs,
    vSelect,
    Search,
  },
  props: {
    data: { type: Object, required: false, default: () => {} },
    showCae: { type: Boolean, required: false, default: true },
    allResearchersAux: { type: Boolean, required: false, default: false },
    allCaesAux: { type: Boolean, required: false, default: false },
    isForm: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      allCaes: false,
      allResearchers: false,
      selectedUsers: [],
      selectedCaes: [],
      user: null,
      searches: [],
      host: null,
      department: null,
      hazard: null,
      workplace: null,
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      users: 'users/fakeUsers',
      hosts: 'institutions/institutions',
      workplaces: 'workplaces/workplaces',
      hazards: 'hazards/hazards',
      caeHosts: 'institutions/caeHosts',
      institution: 'institutions/institution',
    }),
  },
  watch: {
    host() {
      this.selectedUsers = []
    },
    allCaesAux() {
      this.allCaes = this.allCaesAux
    },
    allResearchersAux() {
      this.allResearchers = this.allResearchersAux
    },
    allResearchers() {
      this.saveAudience()
    },
    selectedUsers() {
      this.saveAudience()
    },
    selectedCaes() {
      this.saveAudience()
    },
    data() {
      this.searches = this.data.searches
      this.selectedUsers = this.data.users ?? []
      this.selectedCaes = this.data.caes ?? []
    },
  },
  async mounted() {
    this.selectedCaes = this.data.caes ?? []
    this.allResearchers = this.allResearchersAux
    this.allCaes = this.allCaesAux
    this.searches = this.data.searches
    this.selectedUsers = this.data.users ?? []

    this.loading = false

    if (this.$route.query.host) {
      await this.$store.dispatch('institutions/fetchInstitution', this.$route.query.host)
      this.searchCae(this.institution.name)
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 1000)
  },
  methods: {
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
        this.saveAudience()
      }
    },
    addUser() {
      this.selectedUsers.push(this.user)
    },
    saveAudience() {
      if (!this.loading) {
        this.$emit('saveAudience', {
          all_researchers: this.allResearchers,
          users: this.selectedUsers,
          searches: this.searches,
          host: this.host,
          hazard: this.hazard,
          workplace: this.workplace,
          caes: this.selectedCaes,
        })

        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 300)
      }
    },
    async searchGroup() {
      if (this.host || this.hazard || this.workplace) {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/labour-hazards/groups`, {
          host: this.host ? this.host.id : null,
          hazard: this.hazard ? this.hazard.id : null,
          department: this.department ? this.department.id : null,
          workplace: this.workplace ? this.workplace.id : null,
        })

        if (!this.searches) {
          this.searches = []
        }

        if (!this.selectedUsers) {
          this.selectedUsers = []
        }

        this.searches.push({
          host: this.host,
          hazard: this.hazard,
          department: this.department,
          workplace: this.workplace,
          users: resp.data.data,
          all: true,
        })

        resp.data.data.forEach(item => {
          this.selectedUsers.push(item)
        })

        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 300)
      }
    },
    toggleUser(data) {
      if (data.active) {
        this.selectedUsers.push(data.user)
      } else {
        this.selectedUsers.splice(this.selectedUsers.findIndex(x => x.id === data.user.id), 1)
      }
    },
    removeSearch(index) {
      this.searches.splice(index, 1)
      this.$toastr.success('', 'Search deleted successfully')
    },
    selectCae(event, cae) {
      if (event.target.checked) {
        if (this.selectedCaes.findIndex(x => x.id === cae.id) < 0) {
          this.selectedCaes.push(cae)
        }

        this.caeHosts.splice(this.caeHosts.findIndex(x => x.id === cae.id), 1)
      } else {
        this.caeHosts.push(cae)
        this.selectedCaes.splice(this.selectedCaes.findIndex(x => x.id === cae.id), 1)
      }
    },
    selectAllCae(select) {
      if (select) {
        this.caeHosts.forEach(item => {
          if (this.selectedCaes.findIndex(x => x.id === item.id) < 0) {
            this.selectedCaes.push(item)
          }
        })

        this.caeHosts.splice(0)
      } else {
        this.selectedCaes.forEach(item => {
          this.caeHosts.push(item)
        })

        this.selectedCaes.splice(0)
      }
    },
    async searchCae(name) {
      await this.$store.dispatch('institutions/filterCae', { name })
    },
  },
}
</script>
